import React from 'react'
import { Link } from 'gatsby'
import ourStory from '../../images/effy-web-res-137.jpg'
import FullPageBlock from '../full-page-block'
import ScrollAnimation from 'react-animate-on-scroll'

import "../animations.css"

const OurStory = () => (
  <FullPageBlock style={{ textColor : "inherit", bgColor : "white", height: "110vh"}}>
    <div className="ourstory-left">
      <div className="ourstory-text">
        <ScrollAnimation animateIn="subtleFadeInLeft" duration={0.5}>
          <div id="anchor" className="at-effy">
            At effy,
          </div>
          <div className="who-what">
            Who<br/><span>We</span> are?<br/><br/>&<br/><br/>
            What<br/><span>We</span> do?
          </div>
        </ScrollAnimation>
      </div>
    </div>
    <div className="ourstory-right">
      {/* <ScrollAnimation animateIn="subtleBounceInUp" duration={0.3} delay={500} animateOnce={true}> */}
        <div className="ourstory">
          <Link to="about" className="inherited-link">Our story <span className="arrow">&#8594;</span></Link>
        </div>
        <img className='shadowed' src={ourStory} alt="Our story" />
      {/* </ScrollAnimation> */}
    </div>
  </FullPageBlock>
)

export default OurStory
