import React from 'react'
import Layout from '../components/layout'
import HomeVideo from '../components/homepage/homevideo'
import MobileHome from '../components/homepage/mobilehome'
import OurStory from '../components/homepage/ourstory'
import GetInTouch from '../components/homepage/getintouch'
import Clientele from '../components/homepage/clientele'
import Team from '../components/homepage/team'
import '../components/homepage/testimonial.css'
import mobileScreen from '../images/mobile-screen.jpg'
import fbIcon from '../images/facebook.svg'
import lnIcon from '../images/linkedin.svg'
import twIcon from '../images/twitter.svg'
import inIcon from '../images/instagram.svg'
import kiteIcon from '../images/kite.svg'
import classnames from 'classnames';


import {isMobile} from 'react-device-detect';
import CookieConsent, { Cookies } from "react-cookie-consent";


class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }
  closeSprout() {
    var fadeTarget = document.getElementsByClassName("check-desktop")[0];
    var fadeEffect = setInterval(function () {
        if (!fadeTarget.style.opacity) {
            fadeTarget.style.opacity = 1;
        }
        if (fadeTarget.style.opacity > 0) {
            fadeTarget.style.opacity -= 0.1;
        } else {
            clearInterval(fadeEffect);
        }
    }, 60);
  }
  render() {
    console.log(isMobile);
    if  (isMobile) {
      return(
        <Layout isHomepage={true}>
          <img src={mobileScreen} id="mobile-screen" alt="effy on Mobile" usemap="#social_map"/>

          <span className={classnames('blog-text', this.props.styleName)}>
            <a href='http://blog.effy.co.in' target='_blank'>blog</a>
        </span>
        <span className="in-icon">
        <a href="https://instagram.com/humansofeffy" target='_blank'>
        <img src={inIcon} id='in-icon' />
        </a>
        </span>
        <span className="ln-icon">
        <a href="https://linkedin.com/company/humansofeffy" target='_blank'>
        <img src={lnIcon} id='ln-icon' />
        </a>
        </span>

        <span className="fb-icon">
        <a href="https://facebook.com/humansofeffy" target='_blank'>
        <img src={fbIcon} id='fb-icon' />
        </a>
        </span>
        <span className="tw-icon">
        <a href="https://twitter.com/humansofeffy" target='_blank'>
        <img src={twIcon} id='tw-icon' />
        </a>
        </span>
        <span className="check-desktop">
            <span className='ok-close' onClick={this.closeSprout}>
              OK
            </span>
            <span className='desktop-text'>
               Our mobile version is still sprouting.<br /> Desktop version, done!
            </span>
            <span className='desktop-text-icon'>
              <img src={kiteIcon} id='fb-icon' />
            </span>
        </span>
        </Layout>
      )
    }
    else {
      return(
        <Layout isHomepage={true}>
          <HomeVideo />
          <OurStory />
          <GetInTouch />
          <Team />
          <Clientele />
        </Layout>
      )
    }
  }
}

export default IndexPage
