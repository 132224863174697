import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'gatsby'

import "../animations.css"
import "animate.css/animate.min.css";

import effyGoodTeam from '../../images/effy-good-team.jpg';

const Team = () => (
  <div className="fullpage clear-both">
    <ScrollAnimation animateIn="subtleFadeInLeft">
      <div className="home-team-header">
        <span>A good team is everything.</span>
      </div>
    </ScrollAnimation>
    <div className="home-team-content">
      <div className="home-team-img-section">
        <ScrollAnimation animateIn="subtleBounceInUp" duration={0.4}>
          <img className='shadowed' src={effyGoodTeam} alt="" align="bottom" />
        </ScrollAnimation>
      </div>
      <div className="home-team-text-section">
        <ScrollAnimation animateIn="subtleFadeInUp" delay="300">
          <div className="home-team-text-content">
            <span>We have no road map for you, <br /> you can create your own. Let it be!</span>
            <span>Map out your career, in high resolution.</span>
            <br/>
            <span className="home-come-work-with-us">Come work with us!</span>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="subtleFadeInLeft" delay="500">
          <div className="align-self-end home-know-our-team">
            <Link to="team" className="inherited-link">&#8592; Get to know our Team</Link>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  </div>
)

export default Team
