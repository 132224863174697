import React from 'react'
import { Carousel } from 'react-bootstrap';
import DarrylPhoto from '../../images/darryl-auzmor.png';
import GbPhoto from '../../images/gb-freshworks.png';
import GuillePhoto from '../../images/guille-rideon.png';
import SadiqPhoto from '../../images/sadiq-zudo.png';
import SatyaPhoto from '../../images/satya-vistaar.png';
import VijayPhoto from '../../images/vijay-vistaar.png';

const Testimonial = () => (
  <div className="container">
	<div className="row">
    <span className="testimonails-heading col-sm-12">Our Clientele</span>
    <div className="col-sm-12">
			<Carousel controls={false}>
          <Carousel.Item>
  					<div className="item">
  						<div className="row ">
  							<div className="col-sm-8 align-self-start">
  								<div className="testimonial ">
  									<p>Rockstar team with great enthusiasm and talent. You guys have earned the reputation of Freshworks' go-to partners for solving complex customer usecases. Senthil, your efficient and persuasive language backed up by Joseph's amazing engineering team (Sorry, I've not worked with other stalwarts) is a great combination.<br /><br />
                    One of the important assets that we look for in any partner is responsiveness. You have been amazing. Continue doing that.</p>
  								</div>
  								<div className="media">
  									<div className="media-left d-flex mr-3">
  										<img src={GbPhoto} alt="" />
  									</div>
  									<div className="media-body">
  										<div className="overview">
  											<div className="name"><b>Ganesh Balaji</b></div>
  											<div className="details">Program Manager /Freshworks</div>
  			              </div>
  									</div>
  								</div>
  							</div>
  						</div>
              <div className="row">
                <div className="col-sm-8 offset-md-4">
                  <div className="testimonial">
                    <p>“A group of young hungry entrepreneurs Under one roof” they are flexible and easy to work with. They have a great office.</p>
                  </div>
                  <div className="media">
                    <div className="media-left d-flex mr-3">
                      <img src={DarrylPhoto} alt="" />
                    </div>
                    <div className="media-body">
                      <div className="overview">
                        <div className="name"><b>Darryl Jose</b></div>
                        <div className="details">CEO /Auzmor</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  					</div>
          </Carousel.Item>
          <Carousel.Item>
  					<div className="item">
  						<div className="row ">
  							<div className="col-sm-7 align-self-start">
  								<div className="testimonial ">
  									<p>Cool, effective, professional expertise offered without any hang ups. Good domain expertise displayed throughout the project. For the scope and expertise documentation offered by you guys were fantastic.</p>
  								</div>
  								<div className="media">
  									<div className="media-left d-flex mr-3">
  										<img src={SatyaPhoto} alt="" />
  									</div>
  									<div className="media-body">
  										<div className="overview">
  											<div className="name"><b>Sathya Murali</b></div>
  											<div className="details">Solution Architect /Vistaar</div>
  			              </div>
  									</div>
  								</div>
  							</div>
  						</div>
              <div className="row">
                <div className="col-sm-7 offset-md-5">
                  <div className="testimonial">
                    <p>effy (previously known as Team Alpha) have been working with us over the last 5 years building our in-house bespoke CRM software . They understood our process fully by working with various departments.<br/><br/>
              effy team are technically sound, knowledgeable and flexible. Senthil visited us in the UK when the CRM was going live and stayed for 4 weeks for the launch, training, bugs fixing and to ensure the work flow process was followed correctly.<br/><br/>
              We enjoyed working with Senthil and his team and we would strongly recommend effy</p>
                  </div>
                  <div className="media">
                    <div className="media-left d-flex mr-3">
                      <img src={SadiqPhoto} alt="" />
                    </div>
                    <div className="media-body">
                      <div className="overview">
                        <div className="name"><b>Sadiq Basha</b></div>
                        <div className="details">CEO /Zudo</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  					</div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="item">
  						<div className="row ">
  							<div className="col-sm-7 align-self-start">
  								<div className="testimonial ">
  									<p>effy’s service was outstanding, it was really easy to communicate with them, and to get to a common point. They give you a full description of the job they are going to perform to ensure that all parties are on the same page, which was a nice touch given previous experience with other contractors. But if there was any doubt there was always an open channel of communication and weekly meetings to ensure everything was going the right route.<br /><br />
                It was a really pleasant experience to work with them.</p>
  								</div>
  								<div className="media">
  									<div className="media-left d-flex mr-3">
  										<img src={GuillePhoto} alt="" />
  									</div>
  									<div className="media-body">
  										<div className="overview">
                        <div className="name"><b>Guille</b></div>
                        <div className="details">CTO  /Rideon</div>
  			              </div>
  									</div>
  								</div>
  							</div>
  						</div>
              <div className="row">
                <div className="col-sm-7 offset-md-5">
                  <div className="testimonial">
                    <p>effy and the people we worked with were technically strong. Very easy to work with and understanding.
                The guys were amazing. You worked on better pre-requisites documentation.</p>
                  </div>
                  <div className="media">
                    <div className="media-left d-flex mr-3">
                      <img src={VijayPhoto} alt="" />
                    </div>
                    <div className="media-body">
                      <div className="overview">
                        <div className="name"><b>Vijay Parmar</b></div>
                        <div className="details">Customer Success /Vistaar</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  					</div>
          </Carousel.Item>
        </Carousel>
			</div>
	</div>
</div>

)

export default Testimonial;
