import React from 'react'
import { Link } from 'gatsby'

import homePageVideo from '../../videos/homepage.mp4'
import poster from '../../images/poster.png'

import { Player, ControlBar } from 'video-react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import "video-react/dist/video-react.css"; // import css
import FullPageBlock from '../full-page-block';


const HomeVideo = (props) => (
  <FullPageBlock style={{ textColor : "rgb(255, 255, 255)", bgColor : "none", width: "112%", overflow: 'hidden'}}>
    <div className="row no-margin">
      <div className="col-md-12 col-lg-12 no-padding">
        <div className="video-overlay"></div>
        <div className="homepagevideo-blur flex-column">
          <Player autoPlay={true} loop={true} playbackRate={1} poster={poster} muted >
            <source src={homePageVideo} />
            <ControlBar disabled />
          </Player>
        </div>
        <div className="homepagevideo-text-overlay">
          <div className="homepagevideo-text homepage-wrap flex-column">
            We are not going to change anything!<br/>We are not going to disrupt anything!
          </div>
          <div className="vertical-line"></div>
          <div className="homepagevideo-desc homepage-wrap flex-column" >
            We just want to make g<u>ood software</u><br/> that makes life easier for someone, somewhere.
          </div>
          <div className="homepage-action-container">
            <a className="action-button" href="https://effytech.typeform.com/to/cQm7Um">
              Pitch your idea
            </a>
          </div>
        </div>
        <AnchorLink href='#anchor' offset="60">
          <div className="arrow-container">
            <div className="scroll-down arrow-text">SCROLL</div>
            <div className="arrow-block"><i class="scroll-down icono-arrow1-down"></i></div>
          </div>
        </AnchorLink>
      </div>
    </div>
  </FullPageBlock>
)

export default HomeVideo
