import React from 'react'
import Slider from "react-slick";

import clienteleLogos from '../../images/clientele-logos.png';
import ScrollAnimation from 'react-animate-on-scroll';
import Testimonial from './testimonial';

import "../animations.css"
import "animate.css/animate.min.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Clientele = () => (
  <div className="fullpage clear-both">
  <div className="row no-margin">
      <div className="clientele-left col-md-6">
        <div className="testimonials-section">
            <Testimonial />
        </div>
      </div>
      <div className="clientele-right col-md-6">
        <ScrollAnimation animateIn="subtleFadeInUp" delay="500">
          <img className="clientelelogos" src={clienteleLogos} alt="" />
        </ScrollAnimation>
      </div>
    </div>
  </div>
)

export default Clientele
