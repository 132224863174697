import React from 'react'
import FullPageBlock from '../full-page-block'
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'gatsby'

import "../animations.css"
import "animate.css/animate.min.css";

import meetingRoom from '../../images/effy-web-res-93.jpg'
import meetingRoom2 from '../../images/effy-43.jpg'

const GetInTouch = (props) => (
  <FullPageBlock style={{ textColor : "inherit", bgColor : "none", height: "100vh"}}>
    <div className="getintouch getintouch-left">
      <img className='shadowed' src={meetingRoom} alt="Meeting Room" />
      <ScrollAnimation animateIn="subtleFadeInUp">
        <div className="idea">
          Do you have an idea that helps solve a specific real world problem, that could positively change the way things are done?
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="subtleFadeInUp" delay={300}>
        <div className="listening">
          We are already listening!
        </div>
      </ScrollAnimation>
    </div>
    <div className="getintouch getintouch-right">
      <ScrollAnimation animateIn="subtleFadeInLeft">
        <div className="getintouch-head">
          Get in touch with us.
        </div>
      </ScrollAnimation>
      <div className="meeting-wrap">
        <div className="letustalk ourstory">
          <ScrollAnimation animateIn="subtleFadeInLeft" delay={200}>
            <a href="https://effytech.typeform.com/to/cQm7Um" className="inherited-link">Let us talk <span className="arrow">&#8594;</span></a>
          </ScrollAnimation>
        </div>
        <ScrollAnimation animateIn="subtleFadeInUp" animateOnce={true}>
          <img src={meetingRoom2} className="shadowed meeting-room" align="bottom" />
        </ScrollAnimation>
      </div>
    </div>
  </FullPageBlock>
)

export default GetInTouch
